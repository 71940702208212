<template>
  <div class="container-fluid">
    <div class="page">
      <div v-if="loading" class="text-center">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <Ads />

      <div class="categories-wrapper" v-if="!loading">
        <template>
          <div class="masonry">
            <div class="category" v-for="type in types" :class="{ disabled: !type.enabled }" :key="type.name">
              <span class="count" v-if="type.enabled">{{ type.variants.length }}</span>
              <router-link :to="'/generator/' + type.folderName">
                <CategoryIcons :icon="type.folderName" />
                <div class="category-name">{{ type.name }}</div>
              </router-link>
            </div>
            <div class="category">
              <router-link :to="'/generator/custom'">
                <CategoryIcons :icon="'custom'" />
                <div class="category-name">Custom Handout</div>
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import generatorData from '@/models/generator.json';
import Ads from '../components/Ads.vue';
import CategoryIcons from '../components/CategoryIcons.vue';

export default {
  name: 'Generator',
  components: { Ads, CategoryIcons },
  data: function() {
    return {
      loading: true,
      types: generatorData.data,
    };
  },
  created() {
    if (this.$route.query.code) {
      let apiUrl = 'https://cthulhuarchitect.com/api/patreon.php?code=' + this.$route.query.code;

      fetch(apiUrl, {
        method: 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Server returned ' + response.status + ' : ' + response.statusText);
          }
        })
        .then((response) => {
          if (response.data) {
            if (response.data.id) {
              window.localStorage.setItem('pD', JSON.stringify(response.data));

              const now = new Date();
              window.localStorage.setItem('pDTTL', now.getTime() + 14400000);

              this.$router.replace('/generator');
              window.location.reload();
            }
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    } else {
      this.loading = false;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.custom-handout {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.count {
  position: absolute;
  background: #333;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  right: 5px;
  top: 5px;
  line-height: 28px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.masonry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}

.categories-wrapper {
  max-width: 100%;
  margin: 0 auto 0 auto;
  position: relative;

  .category-name {
    margin-top: 8px;
  }

  .category {
    font-family: 'Klee One', cursive;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px;
    background: #fff;
    border-radius: 7px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    text-transform: capitalize;
    font-size: 24px;
    text-align: center;
    transition: 0.5s all;

    a {
      display: block;
      padding: 30px 30px 20px 30px;
      min-width: 220px;
      text-decoration: none;
      color: #000;
      font-weight: 600;
      transition: 0.5s all;

      &:hover {
        background: #eee;
        border-radius: 7px;
      }
    }

    &:hover {
      filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
    }

    &.disabled {
      img {
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }

      &::after {
        position: absolute;
        content: 'Under Construction';
        bottom: 20px;
        left: calc(50% - 85px);
        padding: 0.5rem;
        min-width: 170px;
        background: #671818;
        color: white;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
        border-radius: 15px;
      }
    }
  }
}
</style>
