import { render, staticRenderFns } from "./GeneratorV2.vue?vue&type=template&id=61edd80e&scoped=true&"
import script from "./GeneratorV2.vue?vue&type=script&lang=js&"
export * from "./GeneratorV2.vue?vue&type=script&lang=js&"
import style0 from "./GeneratorV2.vue?vue&type=style&index=0&id=61edd80e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61edd80e",
  null
  
)

export default component.exports