<template>
    <img :src="'/images/categories/' + icon + '.png'" :alt="icon" width="80" height="80"/>
</template>

<script>
export default {
  props: {
    icon: { type:String, default: 'certificates' }
  }
}
</script>

<style scoped lang="scss">

</style>